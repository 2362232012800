import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon, InfoIcon, ErrorIcon, TaskIcon, PermissionIcon, NeedIcon, EmergencyIcon, BuyItemIcon,BorrowItemIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../../assets/css/support.css'
import { Editor } from 'primereact/editor';
import { Avatar } from 'primereact/avatar';
import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import MyEditor from '../../components/Editor';
import { Calendar } from 'primereact/calendar';

export default function ServiceTaskAdd({service_task=null, type="add"}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,getUserColor,setDialogText,setVisible}= useContext(UtilContext);

    const [closeTab,setCloseTab] = useState(false);

    const [formServiceTask,setFormServiceTask] = useState(service_task);
    const [activeIndex,setActiveIndex] = useState(null);

    const [recipients,setRecipients] = useState(state.users);

    var foundIDs = [];

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    function loopThroughDOM(obj) {
        if(Array.isArray(obj)){
            for(let i=0;i<obj.length;i++){
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("children")){
                    loopThroughDOM(obj[i]["props"]["children"]);
                }
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("data-value")){
                    foundIDs.push(obj[i]["props"]["data-id"]);
                }
            }
        }
        else{
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("children") && obj.props.children!=undefined){
                loopThroughDOM(obj["props"]["children"]);
            }
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("data-value")){
                foundIDs.push(obj["props"]["data-id"]);
            }
        }
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formServiceTask==null?{
            theme:'',description:'',sending_option:'',regularity:'',time_period:'',sending_date:'',sending_time:'',contractor_user:true,sender_id:user.id,
            recipientDepartments:[],recipients:[],
        }:formServiceTask,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            /*setDialogText("Ukladám...");
            setVisible(true);*/
            let formData = new FormData();
            for (let value in values) {
                if(value=="files"){
                    for(let i = 0;i<values[value].length;i++){
                        formData.append('files[]',values[value][i]);
                    }
                }
                else if(value=="recipientDepartments" || value=="recipients"){
                    console.log(value,values[value]);
                    formData.append(value, values[value]?.map(obj => obj.id));
                }
                else if(value !== "regularity" && value !== "time_period" && value!=="sender_id"){
                    formData.append(value, values[value] != null ? values[value] : "");
                }
            }
            var combined = {
                regularity: values.regularity,
                time_period: values.time_period
            }   
            formData.append("sending_data", JSON.stringify(combined));
            formData.append("contractor_id", user.id);
            formData.append("sender_id", values.contractor_user ? user.id : values.sender_id.id);
            let mentions = parse(values.description);
            loopThroughDOM(mentions);
            foundIDs.push(user.id+'');
            //values.recipients.map(recipient => foundIDs.push(recipient.id+''));
            formData.append('mentions', foundIDs.filter(onlyUnique));
            switch(activeIndex){
                case 0: {formData.append('type', 'Info');break;}
                case 1: {formData.append('type', 'Error');break;}
                case 2: {formData.append('type', 'Task');break;}
                case 3: {formData.append('type', 'Permission');break;}
                case 4: {formData.append('type', 'Need');break;}
                case 5: {formData.append('type', 'Borrow');break;}
                case 6: {formData.append('type', 'Emergency');break;}
                default: {formData.append('type', 'Info');break;}
            }
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }
            postRequest('/api/service_tasks', formData,true)
            .then((response) => {
                getRequest("/api/service_tasks").then((response) => {
                    dispatch({type:'load_service_tasks',newServiceTasks:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    foundIDs = [];
                    setActiveIndex(null);
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                setVisible(false);
            }).catch((reason) => {
            setVisible(false);
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
      },
    });

    const weekTimePeriod = [
        {name: 'Pondelok'},
        {name: 'Utorok'},
        {name: 'Streda'},
        {name: 'Štvrtok'},
        {name: 'Piatok'},
        {name: 'Sobota'},
        {name: 'Nedeľa'},
    ];

    const monthTimePeriod = Array.from({length: 31}, (_, i) => i + 1).map((i) =>{
        return {name: ''+i};}
        
    )

    function filterRecipients(departments){
        formik.setFieldValue("recipientDepartments",departments);
        setRecipients(state.employees.filter(employee => employee.date_of_departure==null || employee.date_of_departure=="")
        .filter(employee => departments.map(department => department.id).includes(parseInt(employee?.department_id))));
        if(departments.length==0){
            setRecipients(state.employees.filter(employee => employee.date_of_departure==null || employee.date_of_departure==""));
            formik.setFieldValue("recipients",[])
        }
        else{
            formik.setFieldValue("recipients",state.employees.filter(employee => employee.date_of_departure==null || employee.date_of_departure=="").filter(employee => departments.map(department => department.id).includes(parseInt(employee?.department_id))).map((employee) => ({name:employee.name,id:employee.id})));
        }
    }

    function checkSender(value){
        formik.setFieldValue("contractor_user",value);
        if(value)formik.setFieldValue("sender_id",user.id);
    }

    return (
        <>
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full relative flex flex-col overflow-x h-[calc(100vh_-_137px)]">
            <div className='w-full px-4 py-4 bg-[#333] text-white border rounded-sm'>
                <h1 className='text-center text-3xl font-semibold '>ServisAPP - Zadanie</h1>
            </div>
            <div className='card flex flex-col px-4 py-4 border rounded-sm shadow-xl overflow-y-auto'>
            <Accordion activeIndex={activeIndex} onTabChange={(e) => {setActiveIndex(e.index);formik.resetForm();}}>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=0 ? "hidden" : ""}`} 
                header={<React.Fragment><InfoIcon width={10} height={10}/><span>Oznamy</span></React.Fragment>}>
                        <p>Ak chceš kolegov informovať o niečom dôležitom, napíš tento oznam.<br/>
                            Posielať nepodstatné veci touto formou je zakázané!<br/>
                            Celá história oznamu, ako aj údaje zadávateľa sú archivované.
                        </p>
                            <p className='mb-2'>Ďakujeme!</p>
                        <div className='mb-3'>
                            <label className='font-semibold text-md'>Názov oznamu:</label>
                            <input name='theme' required value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-3'>
                        <label className='font-semibold  text-md'>Popis oznamu:</label>
                        {/*<Editor modules={modules} name='description' value={formik.values.description} onTextChange={(e) => {formik.setFieldValue('description',e.htmlValue);}} style={{ height: '180px' }} />*/}
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        Počet súborov: {}<br/>
                        </div>
                        <div className='mb-3'>
                        <label className='font-semibold  text-md'>Nastavenie času odoslania:</label>
                        <div className='mb-3'>
                        <select 
                        name="sending_option"
                        value={formik.values.sending_option}
                        onChange={(e) => {formik.setFieldValue("sending_option",e.target.value)}}
                        className='bg-white block w-full  px-4 py-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="immediately">Okamžite</option>
                            <option value="once">Nastaviť dátum a čas</option>
                            <option value="repeat">Opakovane</option>
                        </select>
                        </div>
                        {formik.values.sending_option == "once" &&<div className='mb-3'>
                        <label className='font-semibold text-sm'>Dátum odoslania</label>
                        <input
                            name='sending_date'
                            type="date"
                            required
                            onChange={formik.handleChange} value={formik.values.sending_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <label
                            for="sending_time"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Čas odoslania
                        </label>
                        <input
                            name='sending_time'
                            type="time" step="3600"
                            min="05:00" max="22:00"
                            required
                            onChange={formik.handleChange} value={formik.values.sending_time}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>}
                        {formik.values.sending_option == "repeat" && <div className='mb-3'>
                        <label
                            htmlFor="regularity"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Časový plán
                        </label>
                        <select 
                        name='regularity' required
                        onChange={(e) => {formik.setFieldValue("regularity",e.target.value);formik.setFieldValue("time_period",[]);}} value={formik.values.regularity}
                        className={`bg-white block w-full  px-4 py-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber možnosť</option>
                          <option value="Denne">Denne</option>
                          <option value="Týždenne">Týždenne</option>
                          <option value="Mesačne">Mesačne</option>
                        </select>
                        </div>}
                        {formik.values.regularity=="Mesačne" && formik.values.sending_option == "repeat" && <div className='mb-3'>
                        <label
                            htmlFor="time_period"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Pravidelnosť
                        </label>
                        <MultiSelect required value={formik.values.time_period} onChange={(e) => formik.setFieldValue("time_period",e.value)} options={monthTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>}
                        {formik.values.regularity=="Týždenne" && formik.values.sending_option == "repeat" && <div className='mb-3'>
                        <label
                            htmlFor="time_period"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Pravidelnosť
                        </label>
                        <MultiSelect required value={formik.values.time_period} onChange={(e) => formik.setFieldValue("time_period",e.value)} options={weekTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>}
                        {formik.values.sending_option == "repeat" && <div>
                        <label
                            for="sending_time"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Čas odoslania
                        </label>
                        <input
                            name='sending_time'
                            type="time" step="3600"
                            min="05:00" max="22:00"
                            required
                            onChange={formik.handleChange} value={formik.values.sending_time}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>}
                        </div>
                        <div className='mb-3'>
                            <label className='font-semibold  text-md'>Kto posiela tento oznam:</label>
                            {user?.employee != null && <div className='mb-2'><Checkbox onChange={e => checkSender(e.checked)} checked={formik.values.contractor_user} className="p-button-sm p-button-text mr-1"/>
                            <label className='text-sm'>Zhodný so zadávateľom</label></div>}
                            {(formik.values.contractor_user == false || user?.employee==null)&& <div>
                             <Dropdown required value={formik.values.sender_id} onChange={(e) => formik.setFieldValue("sender_id",e.value)} 
                            options={state.users.map((user) => 
                                ({name:user?.employee?.name || user?.username,id:user.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnanca" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                            </div>}
                        </div>
                        <div className='mb-3'>
                            <label className='font-semibold  text-md'>Komu posiela tento oznam:</label>
                        <div>
                        <label
                            htmlFor="recipientDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <MultiSelect onChange={(e) => filterRecipients(e.target.value)} value={formik.values.recipientDepartments} 
                            options={state.departments.map((department) => 
                                ({name:department.name,id:department.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber oddelenia" className="w-full px-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div>
                        <label
                            htmlFor="recipients"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zamestnanec
                        </label>
                        <MultiSelect value={formik.values.recipients} onChange={(e) => formik.setFieldValue("recipients",e.value)} 
                            options={recipients.map((employee) => 
                                ({name:employee.name,id:employee.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnancov" className="w-full px-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=1 ? "hidden" : ""}`} 
                header={<React.Fragment><ErrorIcon width={10} height={10}/><span>Nahlásiť poruchu</span></React.Fragment>}>
                        <p>Ak si zistil poruchu, ktorú treba bezodkladne vyriešiť, tak si na správnom mieste.<br/>
                           Posielať nepodstatné veci touto formou je zakázane.<br/>
                           Celá história poruchy, ako aj údaje zadávateľa sú archivované.
                        </p>
                            <p className='mb-2'>Ďakujeme!</p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Názov poruchy:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis poruchy:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-3'>
                            <label className='font-semibold  text-md'>Kto posiela hlásenie o poruche:</label>
                            <div><Checkbox onChange={e => checkSender(e.checked)} checked={formik.values.contractor_user} className="p-button-sm p-button-text mr-1"/>
                            <label className='text-sm'>Zhodný so zadávateľom</label></div>
                            {formik.values.contractor_user == false && <div>
                             <Dropdown required value={formik.values.sender_id} onChange={(e) => formik.setFieldValue("sender_id",e.value)} 
                            options={state.users.map((user) => 
                                ({name:user?.employee?.name || user?.username,id:user.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnanca" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                            </div>}
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=2 ? "hidden" : ""}`} 
                header={<React.Fragment><TaskIcon width={10} height={10}/><span>Zadať úlohu</span></React.Fragment>}>
                        <p>Ak potrebuješ zadať úlohu zamestnancovi alebo oddeleniu, tak si na správnom mieste.<br/>
                           Tvoj nadriadený preverí, či je to možné a dá pokyn na vykonanie úlohy alebo tvoju úlohu zamietne.<br/>
                           O stave tvojej úlohy budeš priebežne informovaný e-mailom a SMS správou.
                        </p>
                            <p className='mb-2'>Ďakujeme!</p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Názov úlohy:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis úlohy:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-3'>
                            <label className='font-semibold  text-md'>Kto žiada o vykonanie úlohy:</label>
                            <div><Checkbox onChange={e => checkSender(e.checked)} checked={formik.values.contractor_user} className="p-button-sm p-button-text mr-1"/>
                            <label className='text-sm'>Zhodný so zadávateľom</label></div>
                            {formik.values.contractor_user == false && <div>
                             <Dropdown required value={formik.values.sender_id} onChange={(e) => formik.setFieldValue("sender_id",e.value)} 
                            options={state.users.map((user) => 
                                ({name:user?.employee?.name || user?.username,id:user.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnanca" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                            </div>}
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=3 ? "hidden" : ""}`} 
                header={<React.Fragment><PermissionIcon width={10} height={10}/><span>Žiadosť o povolenie</span></React.Fragment>}>
                        <p>Ak potrebuješ zriadiť nové povolenie alebo prístup, napíš túto žiadosť.<br/>
                           Tvoj nadriadený preverí, či je to možné a dá pokyn na zriadenie povolenia alebo tvoju požiadavku zamietne.<br/>
                           O stave tvojej žiadosti budeš priebežne informovaný e-mailom a SMS správou.
                        </p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Názov žiadosti o povolenie:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis žiadosti o povolenie:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-3'>
                            <label className='font-semibold  text-md'>Kto žiada o povolenie:</label>
                            <div><Checkbox onChange={e => checkSender(e.checked)} checked={formik.values.contractor_user} className="p-button-sm p-button-text mr-1"/>
                            <label className='text-sm'>Zhodný so zadávateľom</label></div>
                            {formik.values.contractor_user == false && <div>
                             <Dropdown required value={formik.values.sender_id} onChange={(e) => formik.setFieldValue("sender_id",e.value)} 
                            options={state.users.map((user) => 
                                ({name:user?.employee?.name || user?.username,id:user.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnanca" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                            </div>}
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=4 ? "hidden" : ""}`} 
                header={<React.Fragment><BuyItemIcon width={10} height={10}/><span>Požiadavka na nákup</span></React.Fragment>}>
                        <p>Ak máš potrebu nákupu, napíš túto požiadavku.<br/>
                           Tvoj nadriadený preverí, či je to možné a dá nákupcovi pokyn na nákup alebo tvoju požiadavku zamietne.<br/>
                           O stave tvojej požiadavky budeš priebežne informovaný e-mailom a SMS správou.
                        </p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Názov požiadavky na nákup:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis požiadavky na nákup:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-3'>
                            <label className='font-semibold  text-md'>Kto má požiadavku na nákup:</label>
                            <div><Checkbox onChange={e => checkSender(e.checked)} checked={formik.values.contractor_user} className="p-button-sm p-button-text mr-1"/>
                            <label className='text-sm'>Zhodný so zadávateľom</label></div>
                            {formik.values.contractor_user == false && <div>
                             <Dropdown required value={formik.values.sender_id} onChange={(e) => formik.setFieldValue("sender_id",e.value)} 
                            options={state.users.map((user) => 
                                ({name:user?.employee?.name || user?.username,id:user.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnanca" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                            </div>}
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=5 ? "hidden" : ""}`} 
                header={<React.Fragment><BorrowItemIcon width={10} height={10}/><span>Žiadosť o požičanie</span></React.Fragment>}>
                        <p>Ak si chceš niečo z firmy požičiať, napíš túto žiadosť.<br/>
                           Tvoj nadriadený preverí, či je to možné a dá skladníkovi pokyn na požičanie alebo tvoju požiadavku zamietne.<br/>
                           O stave tvojej žiadosti budeš priebežne informovaný e-mailom a SMS správou.
                        </p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Názov žiadosti o požičanie:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis žiadosti o požičanie:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-3'>
                            <label className='font-semibold  text-md'>Kto žiada o vypožičanie:</label>
                            <div><Checkbox onChange={e => checkSender(e.checked)} checked={formik.values.contractor_user} className="p-button-sm p-button-text mr-1"/>
                            <label className='text-sm'>Zhodný so zadávateľom</label></div>
                            {formik.values.contractor_user == false && <div>
                             <Dropdown required value={formik.values.sender_id} onChange={(e) => formik.setFieldValue("sender_id",e.value)} 
                            options={state.users.map((user) => 
                                ({name:user?.employee?.name || user?.username,id:user.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber zamestnanca" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                            </div>}
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=6 ? "hidden" : ""}`} 
                header={<React.Fragment><EmergencyIcon width={10} height={10}/><span>Núdzová správa</span></React.Fragment>}>
                        <p>Túto správu pošlite iba v prípade, že dôjde k závažným okolnostiam, o ktorých musí byť bezpodmienečne informovaná celá firma.<br/>
                           Pozor! Núdzová správa bude odoslaná všetkým zamestnancom!<br/>
                        </p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Názov núdzovej správy:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis núdzovej správy:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-3'>
                        <label className='font-semibold  text-md'>Nastavenie času odoslania:</label>
                        <div className='mb-3'>
                        <select 
                        name="sending_option"
                        value={formik.values.sending_option}
                        onChange={(e) => {formik.setFieldValue("sending_option",e.target.value)}}
                        className='bg-white block w-full  px-4 py-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="immediately">Okamžite</option>
                            <option value="once">Nastaviť dátum a čas</option>
                        </select>
                        </div>
                        {formik.values.sending_option == "once" &&<div className='mb-3'>
                            <label className='font-semibold text-sm'>Dátum odoslania</label>
                        <input
                            name='sending_date'
                            type="date"
                            required
                            onChange={formik.handleChange} value={formik.values.sending_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <label
                            for="sending_time"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Čas odoslania
                        </label>
                        <input
                            name='sending_time'
                            type="time" step="3600"
                            min="05:00" max="22:00"
                            required
                            onChange={formik.handleChange} value={formik.values.sending_time}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>}
                        {formik.values.regularity=="Týždenne" && formik.values.sending_option == "repeat" && <div className='mb-3'>
                        <label
                            htmlFor="time_period"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Pravidelnosť
                        </label>
                        <MultiSelect required value={formik.values.time_period} onChange={(e) => formik.setFieldValue("time_period",e.value)} options={weekTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>}
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
            </Accordion>
            </div>
        </div>
        </form>
            </>
            );
} 
