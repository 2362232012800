import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { FormikConsumer, useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { deleteRequest, getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon, InfoIcon, ErrorIcon, TaskIcon, PermissionIcon, NeedIcon, EmergencyIcon,EditRowIcon, BellSlashIcon, DeleteIcon, OutlookMsgIcon, BorrowItemIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../../assets/css/support.css'
import { Editor } from 'primereact/editor';
import { ReactTabulator ,reactFormatter} from 'react-tabulator';
import { Avatar } from 'primereact/avatar';
import { DateTime } from "luxon";
import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tag } from 'primereact/tag';
import { Select } from "antd";
import MyEditor from '../../components/Editor';
import { Skeleton } from 'primereact/skeleton';
import MsgReader from '@kenjiuno/msgreader';


export default function ServiceTaskList() {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,getUserColor,getUserInitials,setDialogText,setVisible}= useContext(UtilContext);


    const [showTable,setShowTable] = useState(true);
    const [showTicket,setShowTicket] = useState(false);
    const [showDetail,setShowDetail] = useState(false);
    const [showAddUser,setShowAddUser] = useState(false);

    const [images,setImages] = useState(null);
    const [viewedImage,setViewedImage] = useState('');
    const [visibleFiles,setVisibleFiles] = useState(false);

    const [addedUser,setAddedUser] = useState("");

    const [text, setText] = useState('');

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const op = useRef(null);
    const submitRef = useRef(null);

    const [formServiceTask,setFormServiceTask] = useState();
    const [formType, setFormType] = useState();
    const [searchValue,setSearchValue] = useState("");
    const [statusValue,setStatusValue] = useState("Otvorený");

    const [preview,setPreview] = useState(<></>);


    function getImages(id=null){
        getRequest(`/api/service_tasks/images/${id ? id : formServiceTask.id}`).then((response) => {
            //console.log(response);
            if(response.data!=="{}")setImages(response.data);
            else setImages([]);
        });
    }
    

    const rowClick = (e, row) => {
        console.log(e, row);
     };


    function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex justify-center items-start w-full h-full'><button type='button' onClick={() => dispatch({type:'create_new_tab',name:'Zadanie - Detail',page:'ServiceTaskDetail',props:{task: rowData},mask:'serv004'})}>
        <EditRowIcon/></button></div>;
    }

    function TypeFormatter(props) {
        const rowData = props.cell.getRow().getData();
        switch(rowData.type){
            case 'Notification': {return <InfoIcon width={6} height={6}/>;}
            case 'Error': {return <ErrorIcon  width={6} height={6}/>;}
            case 'Task': {return <TaskIcon  width={6} height={6}/>;}
            case 'Permission': {return <PermissionIcon  width={6} height={6}/>;}
            case 'Need': {return <NeedIcon  width={6} height={6}/>;}
            case 'Borrow': {return <BorrowItemIcon  width={6} height={6}/>;}
            case 'Emergency': {return <EmergencyIcon  width={6} height={6}/>;}
            default: {return <InfoIcon  width={6} height={6}/>;}
        }
    }

    function ContractorFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.contractor?.employee?.name || rowData.contractor?.username;
    }

    function DeleteFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'>
            <button type="button" className='w-fit flex flex-row px-1 py-1 border rounded-md bg-red-900' 
            onClick={() => {deleteTicket(rowData.id)}} ><DeleteIcon/></button></div>;
    }

    const [columns,setColumns] = useState( user.role=="admin" ? [
        { title: '', field: 'editor',hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
        { title: 'Typ', field: 'type',formatter: reactFormatter(<TypeFormatter/>),width:100,resizable:false},
        { title: 'Číslo tiketu', field: 'defined_id',width:150,resizable:false},
        { title: 'Názov', field: 'theme',width: 200,resizable:false},
        { title: 'Status', field: 'status',resizable:false,formatter: reactFormatter(<StatusFormatter/>)},
        { title: 'Zhotoviteľ tiketu', field: 'custom_contractor',formatter: reactFormatter(<ContractorFormatter/>),resizable:false },
        { title: '', field: 'custom_delete',formatter: reactFormatter(<DeleteFormatter/>),resizable:false },
        {field: 'contractor_id',visible: false},
      ] :[
      { title: '', field: 'editor',hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
      { title: 'Typ', field: 'type',formatter: reactFormatter(<TypeFormatter/>),width:100,resizable:false},
      { title: 'Číslo tiketu', field: 'defined_id',width:150,resizable:false},
      { title: 'Názov', field: 'theme',width: 200,resizable:false},
      { title: 'Status', field: 'status',resizable:false,formatter: reactFormatter(<StatusFormatter/>)},
      { title: 'Zhotoviteľ tiketu', field: 'custom_contractor',formatter: reactFormatter(<ContractorFormatter/>),resizable:false },
      {field: 'contractor_id',visible: false},
    ]);

    function renderTicketType(type) {
        switch(type) {
        case 'Info':
            return <div className='flex gap-2 text-gray-800 items-center'><InfoIcon width={8} height={8}/><span>Oznam</span></div>;
        case 'Error':
            return <div className='flex gap-2 text-gray-800 items-center'><ErrorIcon width={8} height={8}/><span>Porucha</span></div>;
        case 'Task':
            return <div className='flex gap-2 text-gray-800 items-center'><TaskIcon width={8} height={8}/><span>Úloha</span></div>;
        case 'Permission':
            return <div className='flex gap-2 text-gray-800 items-center'><PermissionIcon width={8} height={8}/><span>Povolenie</span></div>;
        case 'Need':
            return <div className='flex gap-2 text-gray-800 items-center'><NeedIcon width={8} height={8}/><span>Nákup</span></div>;
        case 'Borrow':
            return <div className='flex gap-2 text-gray-800 items-center'><BorrowItemIcon width={8} height={8}/><span>Požičanie</span></div>;
        case 'Emergency':
            return <div className='flex gap-2 text-gray-800 items-center'><EmergencyIcon width={8} height={8}/><span>Núdzová správa</span></div>;
        default:
            return <div className='flex gap-2 text-gray-800 items-center'><InfoIcon width={8} height={8}/><span>Oznam</span></div>;
        }
    }

    function StatusFormatter(props) {
        const rowData = props.cell.getRow().getData();
        switch(rowData.status) {
        case 'Vytvorený':
            return <Tag className='py-0.5 px-1 bg-gray-400 '>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        case 'Čaká na schválenie nadriadeným':
        case 'Čaká na finálne schválenie':
            return <Tag className='py-0.5 px-1 bg-yellow-400'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        case 'Schválený':
            return <Tag className='py-0.5 px-1 bg-blue-300'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
         case 'Priradený':
            return <Tag className='py-0.5 px-1 bg-blue-500'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        case 'V riešení':
        case 'Objednaný':
        case 'Požičaný':
            return <Tag className='py-0.5 px-1 bg-blue-700'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        case 'Pracuje s obmedzením':
        case 'Pozastavený':
        case 'Vrátený s poškodením':
        case 'Neschválený':
            return <Tag className='py-0.5 px-1 bg-red-500'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        case 'Dodaný':
            return <Tag className='py-0.5 px-1 bg-green-400'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        case 'Odoslaný':
        case 'Aktívny':
        case 'Porucha odstránená':
        case 'ÚIoha vykonaná':
        case 'Povolenie zriadené': 
        case 'Odovzdaný':
        case 'Vrátený bez poškodenia':   
            return <Tag className='py-0.5 px-1 bg-green-600'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        case 'Uzavretý':
            return <Tag className='py-0.5 px-1 bg-gray-700'>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        default:
            return <Tag className='py-0.5 px-1 bg-gray-400 '>
                        <span className="text-base font-normal">{rowData.status}</span>
                    </Tag>;
        }
    }

    const fetchMsgFile = async (url) => {
        const response = await fetch(url);
  
        if (!response.ok) {
            
            //throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
  
        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;    
    };

    async function renderFile(file){
        if(!file)setPreview("");
        //console.log(file.filename.split('.').pop());
        switch(file.filename.split('.').pop()){
            case 'img':
                setPreview(<img src={file.url} className='object-contain h-[75vh] m-auto'></img>);
                break;
            case 'msg':
                // Create a new MsgReader instance with the ArrayBuffer
                const buffer = await fetchMsgFile(file.url);
                const msgReader = new MsgReader(buffer);
                //console.log(msgReader);
                let msg_data = msgReader.getFileData();
                //console.log(msg_data);
                setPreview(<div className='flex flex-col w-full text-balance'>
                    <div className='mb-2'>
                        <label className='font-semibold mr-1'>Dátum:</label>
                        <span>{DateTime.fromHTTP(msg_data.messageDeliveryTime).toFormat("dd.MM.yyyy HH:mm:ss")}</span>
                    </div>
                    <div className='mb-2 '>
                        <label className='font-semibold mr-1'>Prijímatelia:</label>
                        <div>
                            {msg_data.recipients.map((recipient) => {
                                return <>
                                    <div>{recipient.name}</div>
                                    <div>{recipient.smtpAddress}</div>
                                </>
                            })}
                        </div>
                    </div>
                    <div className='mb-2'>
                        <label className='font-semibold mr-1'>Subjekt:</label>
                        <div>{msg_data.subject}</div>
                    </div>
                    <div className='mb-2'>
                        <label className='font-semibold mr-1'>Telo správy:</label>
                        <div>{msg_data.body}</div>
                    </div>
                    <div className='mb-2' >
                        <label className='font-semibold mr-1'>Prílohy:</label>
                        <div>{msg_data.body}</div>
                    </div>
                </div>);
                break;
            default:
                setPreview(<img src={file.url} className='object-contain h-[75vh] m-auto'></img>);
        }
    }

    function matchAny(data, filterParams) {
        var match = false;
        const regex = RegExp(filterParams.value, 'i');
    
        for (var key in data) {
            if (regex.test(data[key]) === true) {
                match = true;
            }
        }
        return match;
      }
    
      function searchTable(value){
        if(searchValue!=""){
            var filters = ref.current.getFilters();
            for(let i=0;i<filters.length;i++){
                if(String(filters[i]["field"]).startsWith("function matchAny")){
                    ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
                }
            }
        }
        if(value!=""){
            ref.current.addFilter(matchAny, {value: value});
        }
        setSearchValue(value);
      }

      function checkStatus(value){
        if(value=="Otvorený"){
            var filters = ref.current.getFilters();
            for(let i=0;i<filters.length;i++){
                if(String(filters[i]["field"]).startsWith("status")){
                    ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
                }
            }
            ref.current.addFilter("status","!=","Uzavretý");
            ref.current.addFilter("status","!=","Stornovaný");
        }
        else if(value=="Uzavretý"){
            var filters = ref.current.getFilters();
            for(let i=0;i<filters.length;i++){
                if(String(filters[i]["field"]).startsWith("status")){
                    ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
                }
            }
            ref.current.addFilter("status","=","Uzavretý");
        }
        else{
            var filters = ref.current.getFilters();
            for(let i=0;i<filters.length;i++){
                if(String(filters[i]["field"]).startsWith("status")){
                    ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
                }
            }
            ref.current.addFilter("status","=","Stornovaný");
        }
        setStatusValue(value);
      }

      function checkContractor(value){
        var filters = ref.current.getFilters();
        for(let i=0;i<filters.length;i++){
            if(String(filters[i]["field"]).startsWith("contractor_id")){
                ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
            }
        }
        if(value=="Mnou vytvorené"){
            ref.current.addFilter("contractor_id","=",user.id);
        }
        else if(value=="Spomenutý"){
            ref.current.addFilter("contractor_id","!=",user.id);
        }
        setStatusValue(value);
      }

      function checkType(value){
        var filters = ref.current.getFilters();
        for(let i=0;i<filters.length;i++){
            if(String(filters[i]["field"]).startsWith("type")){
                ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
            }
        }
        if(value!="All"){
            ref.current.addFilter("type","=",value);
        }
        setStatusValue(value);
      }


    const reject = () => {
        if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }
  

    const acceptTicket = (id) => {
        deleteRequest(`/api/service_tasks/${id}`)
        .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Tiket bol úspešne vymazaný!" });
            getRequest("/api/service_tasks").then((response) => {
                dispatch({type:'load_service_tasks',newServiceTasks:response.data});
            });
        }).catch((reason) => {
            alert(reason);
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Tiket sa nepodarilo vymazať!', life: 3000 });
        })
    };
  

    function deleteTicket(id){
        confirmDialog({
            message: 'Prajete si vymazať tento tiket?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => acceptTicket(id)
        });
    }

    return (
        <>
            <div className='relative flex flex-col bg-gray-100 w-full h-[calc(100vh_-_137px)] py-4 px-4 overflow-y-auto'>
                {showTable && <div className='w-auto bg-white rounded-md shadow-md'>
                    <div>
                        <h2 className='text-left font-semibold text-blue-800 mb-2 p-2'>Servis App - Zadania</h2>
                    </div>
                    <div className='flex flex-row mb-2'>
                    <div className='relative flex flex-row'>
                        <span className="absolute inset-y-0 left-0 flex items-center">
                            <button
                                className="p-2 focus:outline-none focus:ring"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </button>
                        </span>
                        <input
                            type="search"
                            name="Search"
                            placeholder="Hľadať..."
                            onChange={(e) => searchTable(e.target.value)}
                            className={"w-60 pr-4 py-2 ml-1 pl-10 border text-sm rounded-md focus:outline-none"}
                        />
                        </div>
                        <label className='font-semibold py-2 px-2'>Status:</label>
                        <select className='w-fit bg-[#333] text-white px-2 py-1 rounded-md mr-2' onChange={(e) => checkStatus(e.target.value)}>
                            <option value="Otvorený">Otvorený</option>
                            <option value="Uzavretý">Uzavretý</option>
                            <option value="Stornovaný">Stornovaný</option>
                        </select>
                        <select className='w-fit bg-[#333] text-white px-2 py-1 rounded-md mr-2' onChange={(e) => checkContractor(e.target.value)}>
                            <option value="Všetky">Všetky tikety</option>
                            <option value="Mnou vytvorené">Mnou otvorené tikety</option>
                            <option value="Spomenutý">Tikety, kde som spomenutý</option>
                        </select>
                        <label className='font-semibold py-2 px-2'>Typ tiketu:</label>
                        <select className='w-fit bg-[#333] text-white px-2 py-1 rounded-md mr-2' onChange={(e) => checkType(e.target.value)}>
                            <option value="All">Všetky</option>
                            <option value="Info">Oznam</option>
                            <option value="Error">Porucha</option>
                            <option value="Task">Úloha</option>
                            <option value="Permission">Povolenie</option>
                            <option value="Need">Nákup</option>
                            <option value="Borrow">Požičanie</option>
                            <option value="Emergency">Núdzová správa</option>
                        </select>
                    </div>
                    <div>
                        <ReactTabulator
                        data={state.service_tasks} 
                        className='h-[calc(100vh_-_313px)]' //176px
                        onRef={(r) => (ref.current = r.current)}
                        columns={columns}
                        events={{
                            rowClick: rowClick,
                        }}
                        options={{
                            renderHorizontal:"virtual",movableColumns: true,rowHeight:42,
                          printAsHtml:true,printHeader:"<h1>Zoznam zadaní<h1>",printRowRange:"active",
                          initialFilter:[
                            {field:"status", type:"!=", value:"Uzavretý"},
                            {field:"status", type:"!=", value:"Stornovaný"},
                          ],
                          }}
                        />
                    </div>
                </div>}
            </div>
        </>);
} 
