import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import {  PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import AuthContext from '../../components/AuthContext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DateTime } from "luxon";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css';
import UtilContext from '../../components/UtilContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import ReactDOMServer from 'react-dom/server';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "../../assets/css/docviewer.css";
import "@cyntler/react-doc-viewer/dist/index.css";


export default function TrainingEmployeeDetail({training_type, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({'employee.name':true,'employee.department.name':true,custom_last_training_date:true,custom_next_training_date:true,custom_state:true});
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [formTrainingType,setFormTrainingType] = useState(training_type);
    const [formType, setFormType] = useState(type);

    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit training"))?false:true);

    const [visibleFiles,setVisibleFiles] = useState(false);
    const [viewedImage,setViewedImage] = useState('');
    const [images,setImages] = useState('');

    useEffect(() => {
        // Update the document title using the browser API
        if(formTrainingType!=null){
            getImages();
        }
    },[formTrainingType]);

    function getImages(id=null){
        getRequest(`/api/training_types/protocols/${id ? id : formTrainingType.id}`).then((response) => {
            if(response.data!=="{}")setImages(response.data);
        });
    }

      function LastTrainingDate(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?._children.length > 0) return DateTime.fromISO(rowData._children[0].last_training_date).toFormat("dd.MM.yyyy");
        if(new Date(rowData?.initial_date) > new Date())return <span className='text-blue-600'>Naplánované</span>;
        return <span className='text-red-500'>Neškolený</span>;
      }

      function NextTrainingDate(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?._children.length > 0) return DateTime.fromISO(rowData._children[0].next_training_date).toFormat("dd.MM.yyyy");
        if(new Date(rowData?.initial_date) > new Date())return <span className='text-blue-600'>Naplánované</span>;
        return <span className='text-red-500'>Neškolený</span>;
      }

      function StateFormatter(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?.state) return rowData?.state;
        if(rowData?._children.length > 0) return rowData._children[0].state;
        if(new Date(rowData?.initial_date) > new Date())return "OK";
        return <span className='text-red-500'>Neškolený</span>;
      }

      function dateHeaderFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData?._children?.length==0){
          if(headerValue!=""){
            if(new Date(rowData?.initial_date) > new Date())return "Naplánované".toLowerCase().includes(headerValue.toLowerCase());
            return "Neškolený".toLowerCase().includes(headerValue.toLowerCase());
          }
        }
        if(filterParams.name=="last_training_date"){
          return DateTime.fromISO(rowData?._children[0]?.last_training_date).toFormat("dd.MM.yyyy").includes(headerValue);
        }
        else if(filterParams.name=="next_training_date"){
          return DateTime.fromISO(rowData?._children[0]?.next_training_date).toFormat("dd.MM.yyyy").includes(headerValue);
        }
      }

          function stateHeaderFilter(headerValue, rowValue, rowData, filterParams){
            let state = "";
            if(rowData?._children?.length > 0){           
                if(new Date(rowData?._children[0]?.next_training_date) < new Date()){
                    state="NOK";
                }
                else state="OK";
            }
            else if(new Date(rowData?.initial_date) > new Date()){
              state="OK";
            }
            else state="NOK";
            if((rowData?.deactivation_date!=null && rowData?.deactivation_date!="") || (rowData?.employee?.date_of_departure!=null && rowData?.employee?.date_of_departure!=""))state="DEA";
            if(headerValue=="OK NOK")return headerValue.toUpperCase().includes(state.toUpperCase());
            return headerValue.toUpperCase() == state.toUpperCase();
        }
      

    const [columns,setColumns] = useState([
        { title: '', field: 'id',visible:false,print:false},
        { title: 'Meno', field: 'employee.name',headerFilter:"input",minWidth: 200,resizable:false},
        { title: 'Oddelenie', field: 'employee.department.name',minWidth: 200,headerFilter:"input",resizable:false},
        { title: 'Posledné školenie', field: 'custom_last_training_date',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<LastTrainingDate/>),headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"last_training_date"},resizable:false},
        { title: 'Nasled. školenie', field: 'custom_next_training_date',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<NextTrainingDate/>),headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"next_training_date"},resizable:false},
        { title: 'Stav', field: 'custom_state',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<StateFormatter/>),headerFilter:"list", headerFilterParams:{values:{"OK NOK":"OK NOK","OK":"OK","NOK":"NOK"}, clearable:true},headerFilterFunc:stateHeaderFilter,resizable:false},
    ]);

    function setMask(item,value){
        const columnVisible = {...columnsVisible};
       columnVisible[item] = value;
        for(const val in columnVisible) {
          if(columnVisible[val]==false){
            ref.current.hideColumn(`${val}`);
          }
          else{
            ref.current.showColumn(`${val}`);
          }
        }
        setColumnsVisible(columnVisible);
        setTimeout(() => {
          ref.current.redraw(true);
      }, 0);
      }

      const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
            {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("TrainingEmployeeDeatil","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("TrainingEmployeeDeatil","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_4',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];

    function getIndex(training_id) {
      return state.training_types.findIndex(obj => obj.id == training_id);
  }

  async function scrollToFirst(){
      let my_training_type = state.training_types[0];
      setFormTrainingType(my_training_type);
  }
  
  async function scrollUp(){
      let id = getIndex(formTrainingType.id);
      if(id-1>=0){
          setFormTrainingType(state.training_types[id-1]);
      }
  }
  
  async function scrollDown(){
      let id = getIndex(formTrainingType.id);
      if(id+1<state.training_types.length){
          setFormTrainingType(state.training_types[id+1]);
      }
  }
  
  async function scrollToLast(){
      let last = state.training_types.length-1;
      let my_training_type = state.training_types[last];
      setFormTrainingType(my_training_type);
  }

    function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","training_employees");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }
    
      function changeMarkings(value){
        const columnVisible = {...columnsVisible};
        for(let val in columnVisible) {
        if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor"|| columnVisible[val]!=="end_of_validity"){
            columnVisible[val]=value;
            ref.current.hideColumn(`${val}`);
        }
        };
        setColumnsVisible(columnVisible);
        setTimeout(() => {
            ref.current.redraw(true);
        }, 0);
      }

      function initFilter(data, filterParams){
        return !data.employee.date_of_departure && !data.deactivation_date
      }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <IntersectionObserverWrapper>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="print"><button onClick={() => ref.current.print(false,true)}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Detail Školenia","TrainingEmployeeDeatil")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="price"><button onClick={() => setFastView("TrainingEmployeeDetail",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
        </div>
        <Splitter className='h-[calc(100vh_-_177px)]' layout="vertical" pt={{ gutter: { className: 'bg-[#333] h-8' },gutterHandler: { className: 'bg-secondary ' } }}>
    <SplitterPanel className="flex align-items-center justify-content-center overflow-y-auto" minSize={10} size={20}>
        <div className="w-full relative flex flex-col overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2'>
          <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   TYP ŠKOLENIA
                </h1>
                <label className="block text-md font-semibold text-gray-800">Por.č.: {formTrainingType?.id}</label>
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov 
                        </label>
                        <input
                            name='name'
                            type="text"
                            value={formTrainingType?.name} readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="department_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select 
                        name='department_id'
                        value={formTrainingType?.department_id} disabled
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                        </select>
                        </div>  
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                    <div>
                        <label
                            htmlFor="type"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Druh
                        </label>
                        <input
                            name='type'
                            type="text"
                            value={formTrainingType?.type} readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="interval"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            Interval v mesiacoch
                        </label>
                        <input
                            name='interval'
                            type="number"
                            value={formTrainingType?.interval} readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="images"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Predloha:
                        </label>
                        {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                            <tr>
                                <td className='text-black h-10'><div className='flex flex-row p-2'>
                                    <div onClick={() => setVisibleFiles(true)}>
                                        {img.filename.includes(".docx") ?<img src={require('./../../assets/img/word.png')} style={{ height: '65px', width: 'auto'}} alt="Word Logo"/> : <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>}
                                    </div>
                                        <div className='flex flex-col ml-4'>
                                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                        </div>
                                    </div></td>
                            </tr>
                        </table>))}
                        </div>
                    </div>
                </div> 
            </div>
             </SplitterPanel>
             <SplitterPanel className="flex flex-col align-items-center justify-content-center w-full h-full overflow-y-auto" size={40} style={{minHeight: '110px'}}>
             <ReactTabulator
      data={state.training_employees} 
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        tableBuilt: ()=>{
          ref.current.addFilter(initFilter);
        },
      }}
      className='h-full w-full' //176px h-[calc(100vh_-_176px)]
      options={{renderHorizontal:"virtual",rowHeight:30,movableColumns: true,
      printAsHtml:true,printRowRange:"active",printCopyStyle:true,
      layout:"fitColumns",
      initialFilter:[
        {field:"training_id", type:"=", value:training_type.id},
      ],
      printHeader:function(){
        return ReactDOMServer.renderToStaticMarkup(<div className="grid grid-cols-2 gap-1">
            <div className="flex justify-start items-right">
                <img src={require('./../../assets/img/logo-legro.png')} style={{ height: '5rem', width: 'auto'}} alt="Legro Logo"/>
            </div>
            <div className="mt-[10px] flex justify-end items-center">
            <h1 style={{fontSize: '1.875rem',lineHeight: '2.25rem',textAlign: 'left'}}>
                ŠKOLENIE OSOBITNE
            </h1>
          </div>
          <div className="mt-6">
              <div className="md:grid grid-cols-2 gap-1 mb-2">
                <div className='mb-2'>
                  <label
                      htmlFor="name"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Názov
                  </label>
                  <input
                      name='name'
                      type="text"
                      value={formTrainingType.name}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"
                  />
                  </div>
                  <div>
                  <label
                      htmlFor="department_id"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Oddelenie
                  </label>
                  <select 
                  name='department_id'
                  value={formTrainingType.department_id}
                  style={{fontSize: '1rem',width:'100%',height:'42px',padding:'4px 4px 4px 4px',}}
                   className="border rounded-md">
                    <option value="">Vyber oddelenie</option>
                    {state.departments.map((department) => (
                      <option key={department.id} value={department.id}>{department.name}</option>
                    ))}
                  </select>
                  </div>
              </div>
          </div>
          <div className="mt-6">
              <div className="md:grid grid-cols-2 gap-4 mb-2">
                <div className='mb-2'>
                  <label
                      htmlFor="type"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Druh
                  </label>
                  <input
                      name='type'
                      type="text"
                      value={formTrainingType.type}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"
                  />
                  </div>
                  <div>
                  <label
                      htmlFor="interval"
                      className="block text-left text-lg font-semibold text-gray-800"
                  >
                      Interval v mesiacoch
                  </label>
                  <input
                      name='interval'
                      type="text"
                      value={formTrainingType.interval}
                      style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                      className="border rounded-md"
                  />
                  </div>
              </div>
          </div>
          </div>);
        }     
      }}/>
    </SplitterPanel>
    </Splitter> 
            <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2 || index==9) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("TrainingEmployeeDeatil","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
             {viewedImage?.filename?.includes(".docx") ? <DocViewer prefetchMethod="GET" pluginRenderers={DocViewerRenderers} documents={[{uri:viewedImage.url}]} style={{width:'100%',height:'70vh'}} /> : ""}
             {viewedImage?.filename?.includes(".pdf") ? <iframe src={viewedImage.url} className='w-full h-full'></iframe> : ""}
            </Dialog>
            </div>
            </> 
            );
} 
