import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { FormikConsumer, useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { deleteRequest, getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon, InfoIcon, ErrorIcon, TaskIcon, PermissionIcon, NeedIcon, EmergencyIcon,EditRowIcon, BellSlashIcon, DeleteIcon, OutlookMsgIcon, BorrowItemIcon, BotIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../../assets/css/support.css'
import { Editor } from 'primereact/editor';
import { ReactTabulator ,reactFormatter} from 'react-tabulator';
import { Avatar } from 'primereact/avatar';
import { DateTime } from "luxon";
import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tag } from 'primereact/tag';
import { Select } from "antd";
import MyEditor from '../../components/Editor';
import { Skeleton } from 'primereact/skeleton';
import MsgReader from '@kenjiuno/msgreader';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import { Chips } from 'primereact/chips';

export default function ServiceTaskDetail({task=null}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,getUserColor,getUserInitials,setDialogText,setVisible}= useContext(UtilContext);

    const [images,setImages] = useState(null);
    const [viewedImage,setViewedImage] = useState('');
    const [visibleFiles,setVisibleFiles] = useState(false);

    const [addedUser,setAddedUser] = useState("");
    const [addedType,setAddedType] = useState("");
    const [showDetail,setShowDetail] = useState(false);
    const [showAddUser,setShowAddUser] = useState(false);
    const [datetime24h, setDateTime24h] = useState(null);

    const [recipients,setRecipients] = useState(state.employees);
    const [recipientsReadMore,setRecipientsReadMore] = useState(false);

    const [commentClick,setCommentClick] = useState(false);
    const [updatableComment,setUpdatableComment] = useState(0);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const op = useRef(null);
    const submitRef = useRef(null);

    const [formServiceTask,setFormServiceTask] = useState(task);

    var findedIds = [];

    function getImages(id=null){
        getRequest(`/api/service_tasks/images/${id ? id : formServiceTask.id}`).then((response) => {
            //console.log(response);
            if(response.data!=="{}")setImages(response.data);
            else setImages([]);
        });
    }
    
    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };


    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    function loopThroughDOM(obj) {
        if(Array.isArray(obj)){
            for(let i=0;i<obj.length;i++){
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("children")){
                    loopThroughDOM(obj[i]["props"]["children"]);
                }
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("data-value")){
                    findedIds.push(obj[i]["props"]["data-id"]);
                }
            }
        }
        else{
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("children") && obj.props.children!=undefined){
                loopThroughDOM(obj["props"]["children"]);
            }
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("data-value")){
                findedIds.push(obj["props"]["data-id"]);
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            description:''
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            setDialogText("Ukladám...");
            setVisible(true);
            let formData = new FormData();
            for (let value in values) {
                if(value=="files"){
                    for(let i = 0;i<values[value].length;i++){
                        formData.append('files[]',values[value][i]);
                    }
                }
                else formData.append(value, values[value]);
            }
            formData.append('contractor_id',user.id);
            formData.append('task_id',formServiceTask.id);
            let mentions = parse(values.description || "");
            loopThroughDOM(mentions);
            formData.append('mentions', findedIds.filter(onlyUnique));
            postRequest('/api/service_comments', formData,true)
            .then((response) => {
                setFormServiceTask(response.data);
                getImages(response.data.id);
                getRequest("/api/service_tasks").then((response) => {
                    dispatch({type:'load_service_tasks',newServiceTasks:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    setCommentClick(false);
                    findedIds = [];
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                setVisible(false);
            }).catch((reason) => {
                setVisible(false);
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
      },
    });

    const formikUpdate = useFormik({
        initialValues: {
            description:''
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
        setDialogText("Ukladám...");
        setVisible(true);
          let formData = new FormData();
          for (let value in values) {
            if(value=="files"){
                for(let i = 0;i<values[value].length;i++){
                    formData.append('files[]',values[value][i]);
                }
            }
            else formData.append(value, values[value]);
          }
          let mentions = parse(values.description || "");
          loopThroughDOM(mentions);
          formData.append('mentions', findedIds.filter(onlyUnique));
          postRequest(`/api/service_comments/update/${formikUpdate.values.id}`, formData,true)
          .then((response) => {
              setFormServiceTask(response.data);
              getImages(response.data.id);
              getRequest("/api/service_tasks").then((response) => {
                  dispatch({type:'load_service_tasks',newServiceTasks:response.data});
                  if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                  formikUpdate.resetForm();
                  setUpdatableComment(false);
                  findedIds = [];
                  toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Komentár upravený!" });
              });
              setVisible(false);
          }).catch((reason) => {
              setVisible(false);
              if(!navigator.online){
                  alert("Ste offline, dáta budu odoslané po znovupripojení!");
              }
              else{
                  alert("Daný úkon sa nepodaril!");
              }
          });
      },
    });

    useEffect(() => {
        if(formServiceTask != null){
            console.log(formServiceTask);
            getImages(formServiceTask.id);
        }
    }, [formServiceTask]);


    function renderTicketType(type) {
        switch(type) {
        case 'Info':
            return <div className='flex gap-2 text-gray-800 items-center'><InfoIcon width={8} height={8}/><span>Oznam</span></div>;
        case 'Error':
            return <div className='flex gap-2 text-gray-800 items-center'><ErrorIcon width={8} height={8}/><span>Porucha</span></div>;
        case 'Task':
            return <div className='flex gap-2 text-gray-800 items-center'><TaskIcon width={8} height={8}/><span>Úloha</span></div>;
        case 'Permission':
            return <div className='flex gap-2 text-gray-800 items-center'><PermissionIcon width={8} height={8}/><span>Povolenie</span></div>;
        case 'Need':
            return <div className='flex gap-2 text-gray-800 items-center'><NeedIcon width={8} height={8}/><span>Nákup</span></div>;
        case 'Borrow':
            return <div className='flex gap-2 text-gray-800 items-center'><BorrowItemIcon width={8} height={8}/><span>Požičanie</span></div>;
        case 'Emergency':
            return <div className='flex gap-2 text-gray-800 items-center'><EmergencyIcon width={8} height={8}/><span>Núdzová správa</span></div>;
        default:
            return <div className='flex gap-2 text-gray-800 items-center'><InfoIcon width={8} height={8}/><span>Oznam</span></div>;
        }
    }

    function renderTicketStatus(type) {
        switch(type) {
            case 'Vytvorený':
                return <Tag className='bg-gray-400 '>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            case 'Čaká na schválenie':
                return <Tag className='bg-yellow-400'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            case 'Schválený':
                return <Tag className='bg-blue-300'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
                case 'Priradený':
                return <Tag className='bg-blue-500'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            case 'V riešení':
            case 'Objednaný':
            case 'Požičaný':
                return <Tag className='bg-blue-700'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            case 'Pracuje s obmedzením':
            case 'Pozastavený':
            case 'Vrátený s poškodením':
            case 'Neschválený':
                return <Tag className='bg-red-500'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            case 'Dodaný':
                return <Tag className='bg-green-400'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            case 'Odoslaný':
            case 'Aktívny':
            case 'Porucha odstránená':
            case 'ÚIoha vykonaná':
            case 'Povolenie zriadené': 
            case 'Odovzdaný':
            case 'Vrátený bez poškodenia':   
                return <Tag className='bg-green-600'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            case 'Uzavretý':
                return <Tag className='bg-gray-700'>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            default:
                return <Tag className='bg-gray-400 '>
                            <span className="text-base font-normal">{formServiceTask.status}</span>
                        </Tag>;
            }
    }

    const accept = (id) => {
        getRequest(`/api/service_tasks/deleteImage/${id}`)
        .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
            getImages();
        }).catch((reason) => {
            alert(reason);
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
        })
    };

    const reject = () => {
        if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }
    

    function deleteFiles(img){
        confirmDialog({
            message: 'Prajete si vymazať tento súbor?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(img.id)
        });
    }

    function checkNotifiation(){
        return formServiceTask.mentions?.filter((mentioner) => mentioner.id==user.id)[0]?.pivot?.notification;
      }

    function changeStatus(value){
        let formData = new FormData();
        formData.append('status', value);
        postRequest(`/api/service_tasks/updateStatus/${formServiceTask.id}`, formData,true)
        .then((response) => {
            setFormServiceTask(response.data);
            getRequest("/api/service_tasks").then((response) => {
                dispatch({type:'load_service_tasks',newServiceTasks:response.data});
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Status upravený!"});
            });
        }).catch((reason) => {
            alert("Daný úkon sa nepodaril!");
        });
    }

    function changeNotification(){
            let formData = new FormData();
            let value = Number(!checkNotifiation());
            formData.append('value', value);
            postRequest(`/api/service_tasks/updateNotification/${formServiceTask.id}`, formData,true)
            .then((response) => {
                setFormServiceTask(response.data);
                getRequest("/api/service_tasks").then((response) => {
                    dispatch({type:'load_service_tasks',newServiceTasks:response.data});
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: value ? "Notifikácie nastavené" : "Notifikácie zrušené"});
                });
            }).catch((reason) => {
                alert("Daný úkon sa nepodaril!");
            });
        }
    
        function addUser(){
            let formData = new FormData();
            formData.append('mention', addedUser);
            formData.append('type', addedType);
            postRequest(`/api/service_tasks/addUser/${formServiceTask.id}`, formData,true)
            .then((response) => {
                setFormServiceTask(response.data);
                var detail = addedType=="user" ? "Používateľ pridaný" : "Oddelenie pridané";
                setAddedType("");
                setAddedUser("");
                setShowAddUser(false);
                getRequest("/api/service_tasks").then((response) => {
                    dispatch({type:'load_service_tasks',newServiceTasks:response.data});
                    
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: detail});
                });
            }).catch((reason) => {
                alert("Daný úkon sa nepodaril!");
            });
        }

    const weekTimePeriod = [
        {name: 'Pondelok'},
        {name: 'Utorok'},
        {name: 'Streda'},
        {name: 'Štvrtok'},
        {name: 'Piatok'},
        {name: 'Sobota'},
        {name: 'Nedeľa'},
    ];

    const monthTimePeriod = Array.from({length: 31}, (_, i) => i + 1).map((i) =>{
        return {name: ''+i};}
        
    )

    function filterRecipients(departments){
        formik.setFieldValue("recipientDepartments",departments);
        setRecipients(state.employees
        .filter(employee => departments.map(department => department.id).includes(parseInt(employee?.department_id))));
        if(departments.length==0){
            setRecipients(state.employees);
        }
    }

    function content(text){
        return <div className="flex align-items-center">
            <BotIcon/>
            <div className="ml-2">{text}</div>
        </div>;
    }

return (
    <>
    <div className='relative flex flex-col bg-gray-100 w-full py-4 px-4 '>
    <div className='w-auto bg-white rounded-md shadow-md px-10 py-6 h-[calc(100vh_-_170px)]'>
    <div className='flex font-bold text-sm text-blue-800'>LEGRO-SK-Servis / {formServiceTask?.defined_id}</div>
    <div className='flex flex-row gap-11'>
        <div className='basis-2/3'>
          <div className='text-2xl font-light text-black py-6'>{formServiceTask?.theme}</div>
          <div className='flex flex-row justify-between items-center w-full p-4 border border-zinc-600 rounded-sm mb-2'>
            <div><Avatar className='p-overlay-badge' title={formServiceTask?.contractor?.employee?.name || formServiceTask?.contractor?.username} label={getUserInitials(formServiceTask?.contractor?.employee?.name || formServiceTask?.contractor?.username)} 
            shape="circle" style={{ backgroundColor: getUserColor(formServiceTask?.contractor.username), color: '#ffffff',marginRight: '5px'}}/>
            {formServiceTask?.contractor?.employee?.name || formServiceTask?.contractor?.username} založil tiket {DateTime.fromISO(formServiceTask?.created_at).toFormat("dd.MM.yyyy")} o {DateTime.fromISO(formServiceTask?.created_at).toFormat("HH:mm")}</div>
             <button onClick={() => setShowDetail(true)} className='font-semibold'>Detail</button>
          </div>
          {images==null && <Skeleton height="4rem" className="mb-2"></Skeleton>}
          {images?.length>0 && <div>
            <h2 className='text-xl'>Prílohy</h2>
            <div className='flex flex-row flex-wrap w-full h-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
            {images.map((img) => {
                let extension = img.filename.split('.').pop();
                if(extension=="msg"){
                    return <>
                    <div className='text-black h-auto w-52'><div className='flex flex-row p-2'>
                        <div onClick={() => {setViewedImage(img);setVisibleFiles(true);}}>
                            <OutlookMsgIcon/>
                        </div>
                            <div className='flex flex-col ml-4'>
                            <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                            {user.role=="admin" && <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/></button>}
                            </div>
                        </div>
                    </div></>;
                }                                  
                return <>
                <div className='text-black h-auto w-52'><div className='flex flex-row p-2'>
                    <div onClick={() => {setViewedImage(img);setVisibleFiles(true);}}>
                        <embed src={img.url+'#page=1&view=FitH&scrollbar=0'} width='100px' height='auto'></embed>
                    </div>
                        <div className='flex flex-col ml-4'>
                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                        {user.role=="admin" && <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/></button>}
                        </div>
                    </div>
                </div></>;
            })}
        </div>
          </div>}
          {formServiceTask?.type != "Emergency" && <div>
            <h1 className='text-xl font-semibold text-black mb-4'>Aktivita</h1>
            {formServiceTask?.comments.map((comment, index, array) => 
            <div className='flex flex-row w-full justify-between mb-3'>
                <div className='basis-1/10'>
                    <Avatar className='p-overlay-badge' title={comment.contractor?.employee?.name || comment.contractor?.username} label={getUserInitials(comment.contractor?.employee?.name || comment.contractor?.username)} shape="circle" style={{ backgroundColor: getUserColor(comment.contractor.username), color: '#ffffff',marginRight: '5px'}}/>
                </div>
                <div className='flex flex-col grow basis-8/10'>
                        <div className='mb-1'><span className='font-semibold'>{comment.contractor?.employee?.name || comment.contractor?.username}</span> {DateTime.fromISO(comment.updated_at).toFormat("dd.MM.yyyy")} o {DateTime.fromISO(comment.updated_at).toFormat("HH:mm")}</div>
                    {comment?.by_system==0 ? <div>{(array.length - 1 === index && updatableComment) ? 
                        <form onSubmit={formikUpdate.handleSubmit}>
                        <MyEditor editorValue={formikUpdate.description} autofocus={true} formik={formikUpdate}/>
                        <div className='flex flex-row gap-1'>
                        <button typye="button" onClick={() => setUpdatableComment(false)} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-gray-600 rounded-md hover:bg-gray-400 focus:outline-none disabled:opacity-25'>Zrušiť</button>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Upraviť</button>
                        </div>
                    </form> : <MyEditor editorValue={comment.description} hideToolbar={true} hideFileUpload={true} formik={null}/>}{/*parse(comment.description || "")*/}
                    </div> : <Message severity="info" content={() => content(comment?.description)} className='justify-start bg-gray-100' />}
                </div>
                {(array.length - 1 === index && comment.contractor_id == user.id && formServiceTask?.status!="Vyriešený" && comment?.by_system==0) && 
                <div className='flex basis-1/10'>
                    {!updatableComment && <button  onClick={(e) => op.current.toggle(e)}><i className='pi pi-ellipsis-v'></i></button>}
                    <OverlayPanel ref={op}>
                        <div className='flex flex-col'>
                            <div className='flex flex-row items-center hover:bg-gray-200'>
                                <button className='px-4 py-3' onClick={(e) => {formikUpdate.setFieldValue('id',comment.id);formikUpdate.setFieldValue('description',comment.description);setUpdatableComment(true);op.current.toggle();}}><i className='pi pi-pencil mr-2'></i><span className='font-semibold'>Upraviť</span></button>
                            </div>
                            {/*<div className='flex flex-row items-center p-4 pt-2 hover:bg-gray-200'>
                                <button><i className='pi pi-trash mr-2'></i><span className='font-semibold'>Vymazať</span></button>
                            </div>*/}
                        </div>
                    </OverlayPanel>
                </div>}
            </div>)}
            {(formServiceTask?.status!="Uzavretý" && formServiceTask?.status!="Stornovaný") && <div className='flex flex-row mt-3'>
                <div className='basis-1/10'>
                    <Avatar className='p-overlay-badge' title={user?.employee?.name || user?.username} label={getUserInitials(user?.employee?.name || user?.username)} shape="circle" style={{ backgroundColor: getUserColor(user?.username), color: '#ffffff',marginRight: '5px'}}/>
                </div>
                {!commentClick ? <div className='flex flex-col basis-9/10'>
                    <input 
                    type='text' onClick={() => {setCommentClick(true);}}
                    placeholder='Pridať komentár...'
                    className='px-1 py-1 border focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                    />
                </div>:
                <form onSubmit={formik.handleSubmit}>
                    <MyEditor editorValue={formik.values.description} autofocus={true} formik={formik} />
                    <div className='flex flex-row gap-1'>
                        <button typye="button" onClick={() => setCommentClick(false)} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-gray-600 rounded-md hover:bg-gray-400 focus:outline-none disabled:opacity-25'>Zrušiť</button>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
                    </div>
                    </form>}
            </div>}
          </div>}
        </div>
        <div className='basis-1/3 h-[calc(100vh_-_270px)] overflow-y-auto overflow-x-hidden'>
          <div className='flex flex-col mb-3'><label className='font-bold mb-0'>Status</label>
          <div className='text-gray-800 mb-3'>
            {!(user.role=="admin") ? renderTicketStatus(formServiceTask?.status) : 
                <select value={formServiceTask?.status} onChange={(e) => changeStatus(e.target.value)} className='block w-60  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                    <option value="Vytvorený">Vytvorený</option>
                    {!(["Emergency","Info"].includes(formServiceTask.type)) && <option value="Čaká na schválenie nadriadeným">Čaká na schválenie nadriadeným</option>}
                    {!(["Emergency"].includes(formServiceTask.type)) && <option value="Čaká na finálne schválenie">Čaká na finálne schválenie</option>}
                    {!(["Emergency"].includes(formServiceTask.type)) && <option value="Schválený">Schválený</option>}
                    {(["Error","Task"].includes(formServiceTask.type)) && <option value="Priradený">Priradený</option>}
                    {(["Error","Task","Permission"].includes(formServiceTask.type)) && <option value="V riešení">V riešení</option>}
                    {(["Need"].includes(formServiceTask.type)) && <option value="Objednaný">Objednaný</option>}
                    {(["Borrow"].includes(formServiceTask.type)) && <option value="Požičaný">Požičaný</option>}
                    {(["Error"].includes(formServiceTask.type)) && <option value="Pracuje s obmedzením">Pracuje s obmedzením</option>}
                    {(["Task","Permission"].includes(formServiceTask.type)) && <option value="Pozastavený">Pozastavený</option>}
                    {(["Borrow"].includes(formServiceTask.type)) && <option value="Vrátený s poškodením">Vrátený s poškodením</option>}
                    {(["Need"].includes(formServiceTask.type)) && <option value="Dodaný">Dodaný</option>}
                    {(["Info"].includes(formServiceTask.type)) && <option value="Odoslaný">Odoslaný</option>}
                    {(["Info"].includes(formServiceTask.type)) && <option value="Aktívny">Aktívny</option>}
                    {(["Error"].includes(formServiceTask.type)) && <option value="Porucha odstránená">Porucha odstránená</option>}
                    {(["Task"].includes(formServiceTask.type)) && <option value="Úloha vykonaná">Úloha vykonaná</option>}
                    {(["Permission"].includes(formServiceTask.type)) && <option value="Povolenie zriadené">Povolenie zriadené</option>}
                    {(["Need"].includes(formServiceTask.type)) && <option value="Odovzdaný">Odovzdaný</option>}
                    {(["Borrow"].includes(formServiceTask.type)) && <option value="Vrátený bez poškodenia">Vrátený bez poškodenia</option>}
                    <option value="Uzavretý">Uzavretý</option>
                </select>
            }
          </div>
          <div className='flex flex-col mb-3'><label className='font-bold mb-1'>Typ tiketu</label>
          {renderTicketType(formServiceTask?.type)}
          </div>
          </div>
          {(["Info","Emergency"].includes(formServiceTask?.type)) &&<div className='mb-3'>
            {formServiceTask?.type=="Info" ? <span className='text-gray-800'>Posledný krát odoslaný: XX.XX.XX-XX:XX</span> : <span className='text-gray-800'>Odoslaný: XX.XX.XX-XX:XX</span>}
          </div>}
          {formServiceTask?.type=="Emergency" && <div className='mb-3'>
            <span className='text-gray-800'>Správa bola poslaná všetkým zamestnancom!</span>
          </div>}
          {!(["Emergency"].includes(formServiceTask?.type)) && <><div className='mb-3'>
            <button className="flex flex-row gap-2 disabled:opacity-25" onClick={() => changeNotification()} disabled={formServiceTask?.status=="Vyriešený"}>
                {formServiceTask?.mentions?.filter((mentioner) => mentioner.id==user.id)[0]?.pivot?.notification ? <BellSlashIcon/> : <i title='Notifikácie' className="pi pi-bell" style={{ fontSize: '1.4rem', marginRight:5 }}></i>}
                <span className='text-gray-800'>{formServiceTask?.mentions?.filter((mentioner) => mentioner.id==user.id)[0]?.pivot?.notification ? "Vypnúť" : "Zapnúť" } notifikácie</span>
            </button>
          </div>
          <div className='mb-5'>
            <button type="button" className='flex flex-row gap-2 disabled:opacity-25' disabled={user.id !== formServiceTask?.contractor_id} onClick={() => changeStatus("Stornovaný")}>
                <i title='Storno' className="pi pi-ban" style={{ fontSize: '1.4rem', marginRight:5 }}></i>
                <span className='text-gray-800'>Storno</span>
            </button>
          </div></>}
          {(["Borrow","Need","Permission","Task"].includes(formServiceTask?.type)) && <div className='flex flex-col mb-5'>
            <label className='font-bold mb-1'>Schvaľovanie nadriadeným</label>
            <label className='font-normal text-md mb-1'>Čaká na schválenie</label>
            <Dropdown value={formik.values.contractor_id} onChange={(e) => formik.setFieldValue("contractor_id",e.value)} 
                options={state.employees.map((employee) => 
                    ({name:employee.name,id:employee.id})
                )} 
                optionLabel="name"
                filter placeholder="Vyber zamestnanca" className="w-60 px-2 py-0 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                <div className='mt-2'>
                <label className='font-normal text-md mb-1'>Stav</label>
                    <select className="block w-60 px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                        <option value="">Vyber možnosť</option>
                        <option value="Schválené">Schválené</option>
                        <option value="Neschválené">Neschválené</option>
                    </select>
                </div>
          </div>}
          {!(["Emergency","Error"].includes(formServiceTask?.type)) && <div className='flex flex-col mb-5'>
            <label className='font-bold mb-1'>Finálne schvaľovanie</label>
            <label className='font-normal text-md mb-1'>Čaká na schválenie</label>
            <Dropdown value={formik.values.contractor_id} onChange={(e) => formik.setFieldValue("contractor_id",e.value)} 
                options={state.employees.map((employee) => 
                    ({name:employee.name,id:employee.id})
                )} 
                optionLabel="name"
                filter placeholder="Vyber zamestnanca" className="w-60 px-2 py-0 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                <div className='mt-2'>
                <label className='font-normal text-md mb-1'>Stav</label>
                    <select className="block w-60 px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                        <option value="">Vyber možnosť</option>
                        <option value="Schválené">Schválené</option>
                        <option value="Neschválené">Neschválené</option>
                    </select>
                </div>
          </div>}
          {(["Error","Task"].includes(formServiceTask?.type)) && <div className='mb-3'>
            <label className='font-bold mb-1'>Úlohu má vykonať:</label>
            <div>
                <MultiSelect value={formik.values.recipients} onChange={(e) => formik.setFieldValue("recipients",e.value)} 
                options={recipients.map((employee) => 
                    ({name:employee.name,id:employee.id})
                )} 
                optionLabel="name"
                filter placeholder="Vyber zamestnancov" className="w-60 px-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
            </div>
          </div>}
          <div className='flex flex-col mb-5'><label className='font-bold mb-1'>Zainteresované oddelenia</label>
          {formServiceTask?.departments.map(department => 
                <div className='flex items-center gap-2 text-gray-800 mb-2'>
                    <Avatar className='p-overlay-badge' title={department.name} label={getUserInitials(department.name)} shape="circle" style={{ backgroundColor: getUserColor(department.name), color: '#ffffff',marginRight: '5px'}}/>
                    <div className='flex flex-col'>
                        <span>{department.name}</span>
                        {/*formServiceTask?.contractor_id == user.id && <span className='text-sm text-gray-500'>Zakladateľ</span>*/}
                    </div>
                </div>
          )}
          <div className='text-gray-800'>
            <button type="button" onClick={() => {setAddedType("department");setShowAddUser(true);}} className='flex items-center gap-2 disabled:opacity-25' disabled={formServiceTask?.status=="Vyriešený"}>
                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie
            </button>
          </div>
          </div>
          <div className='flex flex-col mb-5'><label className='font-bold mb-1'>Zainteresovaní používatelia</label>
          {formServiceTask?.mentions.map(user => 
                <div className='flex items-center gap-2 text-gray-800 mb-2'>
                    <Avatar className='p-overlay-badge' title={user?.employee?.name || user?.username} label={getUserInitials(user?.employee?.name || user?.username)} shape="circle" style={{ backgroundColor: getUserColor(user.username), color: '#ffffff',marginRight: '5px'}}/>
                    <div className='flex flex-col'>
                        <span>{user?.employee?.name || user?.username}</span>
                        {formServiceTask?.contractor_id == user.id && <span className='text-sm text-gray-500'>Zakladateľ</span>}
                    </div>
                </div>
          )}
          <div className='text-gray-800'>
            <button type="button" onClick={() => {setAddedType("user");setShowAddUser(true);}} className='flex items-center gap-2 disabled:opacity-25' disabled={formServiceTask?.status=="Vyriešený"}>
                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
            </button>
          </div>
          </div>
        </div>
    </div>
</div>
<Dialog header="Detail tiketu" visible={showDetail} style={{ width: '70vw' }} onHide={() => setShowDetail(false)}>
                <div className='w-auto flex flex-col'>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Názov:</label>
                            <input name='theme' disabled value={formServiceTask?.theme} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Popis:</label>
                            <MyEditor editorValue={formServiceTask?.description} hideToolbar={true} hideFileUpload={true} formik={null} />
                        </div>
                        <div className='mb-2'>
                        {["Info","Emergency"].includes(formServiceTask?.type) && <><label className='font-semibold  text-sm'>Nastavenie času odoslania:</label>
                        <select 
                        disabled
                        name="sending_option"
                        value={formServiceTask?.sending_option}
                        className='bg-white block w-full  px-4 py-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="immediately">Okamžite</option>
                            <option value="once">Nastaviť dátum a čas</option>
                            {["Info"].includes(formServiceTask?.type) && <option value="repeat">Opakovane</option>}
                        </select></>}
                        {formServiceTask?.sending_option == "once" &&<div className='mb-3'>
                            <label className='font-semibold text-sm'>Dátum odoslania</label>
                        <input
                            name='sending_date'
                            type="date"
                            readOnly value={formServiceTask?.sending_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <label
                            for="sending_time"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Čas odoslania
                        </label>
                        <input
                            name='sending_time'
                            type="time" step="3600"
                            min="05:00" max="22:00"
                            readonly value={formServiceTask?.sending_time}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>}
                        {formServiceTask?.sending_option == "repeat" && <div className='mb-3'>
                        <label
                            htmlFor="regularity"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Časový plán
                        </label>
                        <select 
                        readOnly
                        name='regularity' required
                        value={JSON.parse(formServiceTask.sending_data).regularity}
                        className={`bg-white block w-full  px-4 py-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                            <option value="">Vyber možnosť</option>
                            <option value="Denne">Denne</option>
                            <option value="Týždenne">Týždenne</option>
                            <option value="Mesačne">Mesačne</option>
                        </select>
                        </div>}
                        {JSON.parse(formServiceTask.sending_data).regularity=="Mesačne" && formServiceTask?.sending_option == "repeat" && <div className='mb-3'>
                        <label
                            htmlFor="time_period"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Pravidelnosť
                        </label>
                        <MultiSelect disabled value={JSON.parse(formServiceTask.sending_data).time_period.map((time_period) => ({name:time_period.name}))} options={monthTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>}
                        {JSON.parse(formServiceTask.sending_data).regularity=="Týždenne" && formServiceTask?.sending_option == "repeat" && <div className='mb-3'>
                        <label
                            htmlFor="time_period"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Pravidelnosť
                        </label>
                        <MultiSelect disabled value={JSON.parse(formServiceTask.sending_data).time_period.map((time_period) => ({name:time_period.name}))} options={weekTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>}
                        </div>
                        <div className='mb-2'>
                           {["Info","Error","Task","Permission","Need","Borrow"].includes(formServiceTask?.type) && <><label className='font-semibold  text-sm'>Kto posiela tento oznam:</label>
                            <input
                            name='sender_id'
                            type="text"
                            readonly value={state.users.filter(user => user.id == formServiceTask?.sender_id)[0]?.employee?.name || state.users.filter(user => user.id == formServiceTask?.sender_id)[0]?.username}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                            </>}
                        </div>
                        <div className='mb-2'>
                        {["Info"].includes(formServiceTask?.type) && <> <label className='font-semibold  text-sm'>Komu posiela tento oznam:</label>
                           <div>
                            <label
                                htmlFor="recipientDepartment"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Oddelenia
                            </label>
                            <Chips value={formServiceTask?.departments?.map((department) => department.name)} disabled className={` px-4 py-2 opacity-100 h-full `}/>
                            {/*<MultiSelect disabled value={formServiceTask?.departments.map((department) => 
                                    ({name:department.name,id:department.id})
                                )} 
                                options={state.departments.map((department) => 
                                    ({name:department.name,id:department.id})
                                )} 
                                optionLabel="name"
                                filter placeholder="Vyber oddelenia" className="w-full px-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>*/}
                            </div>
                            <div >
                            <label
                                htmlFor="recipients"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Zamestnanci
                            </label>
                            <Chips value={formServiceTask?.employees?.map((employee) => employee.name)} disabled className={` px-4 py-2 ${
                            !recipientsReadMore
                                ? 'max-h-52 opacity-100 overflow-y-hidden mb-0'
                                : 'max-h-250 opacity-100 mb-4'
                            } `}/>
                            {formServiceTask?.employees?.length>15 && <button className='flex justify-center items-center mt-4 w-fit h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25' onClick={() => setRecipientsReadMore(!recipientsReadMore)}>{recipientsReadMore ? 'Zobraziť menej' : 'Zobraziť viac'}</button>}
                            {/*<MultiSelect disabled value={formServiceTask?.employees?.map((employee) => 
                                    ({name:employee.name,id:employee.id})
                                )}  
                                options={recipients.map((employee) => 
                                    ({name:employee.name,id:employee.id})
                                )}  
                                optionLabel="name"
                                filter placeholder="Vyber zamestnancov" className="w-full px-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>*/}
                            </div></>}
                        </div>
                </div>
            </Dialog>
            <Dialog header={addedType=="user" ? "Pridanie používateľa" : "Pridanie oddelenia"} visible={showAddUser} style={{ width: '70vw' }} onHide={() => {setShowAddUser(false);setAddedUser("");setAddedType("");}}>
                <div className='w-auto flex flex-col'>
                {addedType=="user" ? <Select showSearch={true} optionFilterProp="children" value={addedUser} onChange={(value) => setAddedUser(value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                    <Select.Option disabled selected value="">Vyber používateľa</Select.Option>
                    {state.users?.filter(user => !formServiceTask.mentions.map(mention => mention.id).includes(user.id)).map((user) => (
                  <Select.Option key={user.id} value={user.id}>{user?.employee?.name || user?.username}</Select.Option>
                  ))}
                </Select> : 
                <Select showSearch={true} optionFilterProp="children" value={addedUser} onChange={(value) => setAddedUser(value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                    <Select.Option disabled selected value="">Vyber oddelenie</Select.Option>
                    {state.departments?.filter(department => !formServiceTask.departments.map(dep => dep.id).includes(department.id)).map((department) => (
                  <Select.Option key={department.id} value={department.id}>{department.name}</Select.Option>
                  ))}
                </Select>}
                </div>
                <div>
                <button type="submit" disabled={addedUser==""} onClick={() => addUser()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Uložiť</button>
                </div>
            </Dialog>
            {/*<Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
              {preview}
            </Dialog>*/}
            </div>
</>);
} 