import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { deleteRequest, getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, DeleteIcon, InfoIcon, TaskIcon, ErrorIcon, PermissionIcon, NeedIcon, BorrowItemIcon, EmergencyIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { SelectButton } from 'primereact/selectbutton';
import { Avatar } from 'primereact/avatar';
import { Select } from "antd";
import { confirmDialog } from 'primereact/confirmdialog';


export default function ServiceUsersSettings() {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView,getUserColor,getUserInitials,setDialogText,setVisible} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const [addedUser,setAddedUser] = useState("");
    const [showDetail,setShowDetail] = useState(false);
    const [showAddUser,setShowAddUser] = useState(false);

    const ref = useRef(null);


    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };


    const formik = useFormik({
            initialValues: {
                type:'',user_id:'',department_id:'',role:''
            },
            enableReinitialize:true,
            validate,
            onSubmit: values => {
                setDialogText("Ukladám...");
                setVisible(true);
                let formData = new FormData();
                for (let value in values) {   
                    formData.append(value, values[value] != null ? values[value] : "");
                }
                for (var pair of formData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]);
                }
                postRequest('/api/service_user_settings', formData,true)
                .then((response) => {
                    setShowAddUser(false);
                    getRequest("/api/service_user_settings").then((response) => {
                        dispatch({type:'load_service_user_settings',newServiceUserSettings:response.data});
                        formik.resetForm();
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Používateľ bol úspešne pridaný!" });
                    });
                    setVisible(false);
                }).catch((reason) => {
                    setShowAddUser(false);
                    setVisible(false);
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                });
          },
        });


    const reject = () => {
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
        }
        
    
    const accept = (id) => {
        deleteRequest(`/api/service_user_settings/${id}`)
        .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Nastavenie bolo úspešne vymazané!" });
            getRequest("/api/service_user_settings").then((response) => {
                dispatch({type:'load_service_user_settings',newServiceUserSettings:response.data});
            });
        }).catch((reason) => {
            alert(reason);
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Nastavenie sa nepodarilo vymazať!', life: 3000 });
        })
    };
    

    function deleteSetting(id){
        confirmDialog({
            message: 'Prajete si vymazať nastavenie?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(id)
        });
    }


    return ( 
        <>
        <Toast ref={ownToast}/>
        <div className="h-[calc(100vh-136px)] bg-gray-100 overflow-y-auto overflow-x-hidden">
        <div className='grid grid-flow-row auto-rows-max items-start xl:grid-cols-3 lg:grid-cols-2 gap-3'>
            <div className="w-auto min-w-[400px] p-6 m-4 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900 mb-8">
                   <InfoIcon width={8} height={8}/>OZNAMY
                </h1>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <label className='font-bold mb-1 text-lg'>Správca oznamov:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Info").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Info");formik.setFieldValue("role","manager");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="w-auto min-w-[400px] p-6 m-4 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900 mb-8">
                   <TaskIcon width={8} height={8}/>ÚLOHY
                </h1>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <label className='font-bold mb-1 text-lg'>Správca úloh:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Task" && setting.role=="manager").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800 mb-8'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Task");formik.setFieldValue("role","manager");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                            <label className='font-bold mb-1 text-lg'>Finálne schválenie:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Task" && setting.role=="final_approval").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Task");formik.setFieldValue("role","final_approval");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="w-auto min-w-[400px] p-6 m-4 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900 mb-8">
                   <ErrorIcon width={8} height={8}/>PORUCHY
                </h1>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-8">
                    <label className='font-bold mb-1 text-lg'>Oddelenia pre odstránenie poruchy:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Error" && setting.role=="eliminator").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.department?.name} label={getUserInitials(setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.department?.name}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Error");formik.setFieldValue("role","eliminator");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie
                            </button>
                            </div>
                    </div>
                    <div className="flex flex-col mb-2">
                    <label className='font-bold mb-1 text-lg'>Používatelia pre notifikácie o poruche:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Error" && setting.role=="reciever").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Error");formik.setFieldValue("role","reciever");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="w-auto min-w-[400px] p-6 m-4 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900 mb-8">
                   <PermissionIcon width={8} height={8}/>POVOLENIA
                </h1>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <label className='font-bold mb-1 text-lg'>Správca povolení:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Permission" && setting.role=="manager").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800 mb-8'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Permission");formik.setFieldValue("role","manager");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                            <label className='font-bold mb-1 text-lg'>Finálne schválenie:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Permission" && setting.role=="final_approval").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Permission");formik.setFieldValue("role","final_approval");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="w-auto min-w-[400px] p-6 m-4 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900 mb-8">
                   <NeedIcon width={8} height={8}/>NÁKUPY
                </h1>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <label className='font-bold mb-1 text-lg'>Nákupca:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Need" && setting.role=="buyer").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800 mb-8'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Need");formik.setFieldValue("role","buyer");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                            <label className='font-bold mb-1 text-lg'>Nadriadený pre schvaľovanie:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Need" && setting.role=="manager").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800 mb-8'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Need");formik.setFieldValue("role","manager");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                            <label className='font-bold mb-1 text-lg'>Finálne schválenie:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Need" && setting.role=="final_approval").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Need");formik.setFieldValue("role","final_approval");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="w-auto min-w-[400px] p-6 m-4 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900 mb-8">
                   <BorrowItemIcon width={8} height={8}/>POŽIČOVŇA
                </h1>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <label className='font-bold mb-1 text-lg'>Skladník:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Borrow" && setting.role=="manager").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800 mb-8'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Borrow");formik.setFieldValue("role","manager");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                            <label className='font-bold mb-1 text-lg'>Finálne schválenie:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Borrow" && setting.role=="final_approval").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Borrow");formik.setFieldValue("role","final_approval");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="w-auto min-w-[400px] p-6 m-4 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900 mb-8">
                   <EmergencyIcon width={8} height={8}/>NÚDZOVÉ SPRÁVY
                </h1>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <label className='font-bold mb-1 text-lg'>Správca núdzových správ:</label>
                            <div className='h-auto max-h-[170px] overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                            {state.service_user_settings.filter(setting => setting.type=="Emergency").map(setting => 
                                <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                    <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                        <div className='flex justify-left flex-col justify-center'>
                                            <span>{setting?.user?.employee?.name || setting?.user?.username}</span>
                                        </div>
                                    </div>
                                    <div className='px-1'>
                                        <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='text-gray-800'>
                            <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("type","Emergency");formik.setFieldValue("role","manager");setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
            <button className='hidden' type='submit' ref={submitRef}></button>
            <Dialog header="Pridanie používateľa" visible={showAddUser} style={{ width: '70vw' }} onHide={() => {setShowAddUser(false);setAddedUser("");}}>
                <form onSubmit={formik.handleSubmit}>
                <div className='w-auto flex flex-col'>
                {(formik.values.role != "eliminator") ? <Select showSearch={true} optionFilterProp="children" value={formik.values.user_id} onChange={(value) => formik.setFieldValue("user_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                    <Select.Option disabled selected value="">Vyber používateľa</Select.Option>
                    {state.users?.filter(user => !state.service_user_settings.filter(setting => setting.type == formik.values.type).map(setting => setting.user_id).includes(user.id)).map((user) => (
                        <Select.Option key={user.id} value={user.id}>{user?.employee?.name || user?.username}</Select.Option>
                    ))}
                </Select> : 
                <Select showSearch={true} optionFilterProp="children" value={formik.values.department_id} onChange={(value) => formik.setFieldValue("department_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                    <Select.Option disabled selected value="">Vyber oddelenie</Select.Option>
                    {state.departments?.filter(department => !state.service_user_settings.filter(setting => setting.type == formik.values.type && setting.role == formik.values.role).map(setting => setting.department_id).includes(department.id)).map((department) => (
                        <Select.Option key={department.id} value={department.id}>{department.name}</Select.Option>
                    ))}
                </Select>}
                </div>
                <div>
                <button type="submit" disabled={formik.values.user_id=="" && formik.values.department_id==""} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Uložiť</button>
                </div>
                </form>
            </Dialog>
            </> 
            );

}