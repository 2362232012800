import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { SelectButton } from 'primereact/selectbutton';

export default function TrainingGeneralSettings() {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const ref = useRef(null);

    const options = ['Vyp', 'Zap'];
    const [inNotifications,setInNotifications] = useState(options[0]);
    const [inLists,setInLists] = useState(options[0]);


    function changeInNotifications(value){
        setInNotifications(value);
        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Hodnota bola úspešne zmenená!" });
    }

    function changeInLists(value){
        setInLists(value);
        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Hodnota bola úspešne zmenená!" });
    }

    return ( 
        <>
        <Toast ref={ownToast}/>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)] bg-gray-100">
            <div className="w-1/2 min-w-[600px] p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900 mb-8">
                   VŠEOBECNÉ NASTAVENIA
                </h1>
                <div className='ml-3'>
                <h3 className="text-xl font-medium text-left text-zinc-900 mb-8">
                   Zobrazenie NOK deaktivovaných školení
                </h3>
                <div className='flex flex-col'>
                    <div className="flex justify-between items-center mb-2">
                        <label className='block text-md font-semibold text-gray-800 mr-2'>V notifikáciách:</label>
                        <SelectButton value={inNotifications} onChange={(e) => changeInNotifications(e.value)} options={options} />
                    </div>
                    <div className="flex justify-between items-center">
                        <label className='block text-md font-semibold text-gray-800 mr-2'>V zoznamoch:</label>
                        <SelectButton value={inLists} onChange={(e) => changeInLists(e.value)} options={options} />
                    </div>
                </div>
                </div>
            </div>
            </div>
            <button className='hidden' type='submit' ref={submitRef}></button>
            </> 
            );

}